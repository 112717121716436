import { computed, ref, watch } from 'vue'
import * as AnalyticEvents from '@web/services/Analytics/events'
import OneSignal from '@web/common/OneSignal'
import sentry, { sentryConfigure } from '@web/common/sentry'
import { useStore } from '@web/store'
import Token from '@web/common/token'
import urlParse from 'url-parse'
import { IS_WEB } from '@web/common/config'
import i18n from '@web/plugins/i18n'
import PAGE_NAME from '@web/consts/PAGE_NAME'

interface Phone {
  countryCode: string;
  isValid: boolean;
  countryCallingCode?: string;
  e164?: string;
  formatInternational?: string;
  formatNational?: string;
  formattedNumber?: string;
  nationalNumber?: string;
  phoneNumber?: string;
  type?: string | undefined;
  uri?: string;
}

const store = useStore()

export const useNotice = () => computed({
  get () {
    return store.getters['user/notice']
  },
  set (value: string) {
    store.commit('user/setNotice', value)
  }
})

export const useUsername = email => computed({
  get () {
    return email.value
  },
  set (value: string) {
    email.value = value.toLowerCase()
  }
})

export default function ({ router, serverErrors, isLoadDataAfterSuccess }) {
  const email = ref('')
  const password = ref('')
  const passwordAgain = ref('')
  const phone = ref('')
  const newPhone = ref<Phone | null>(null)
  const save = ref(true)
  const showPassword = ref(false)
  const showPasswordAgain = ref(false)
  const logo = computed(() => store.getters['assets/logo'])
  const clients = computed(() => store.getters['assets/authCollection'])
  const username = useUsername(email)
  const notice = useNotice()

  function redirectAfterSignUp () {
    if (isLoadDataAfterSuccess) {
      store.dispatch('fetchAllData')
    }
    const returnUrl: string | null = router.currentRoute.value.query.returnUrl as string | null
    if (returnUrl) {
      const URLParseInstance = urlParse(returnUrl, true)
      URLParseInstance.set('query', {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...URLParseInstance.query,
        token: Token.get()
      })
      window.location.href = URLParseInstance.toString()
    } else if (store.state.main.returnUrl && store.state.main.returnUrl !== '/') {
      router.push(store.state.main.returnUrl)
    } else {
      if (store.getters['assets/authCollection'].length) {
        router.push({ name: 'socialNetworks' })
      } else {
        router.push({ name: PAGE_NAME.HOME })
      }
    }
  }

  email.value = email.value || store.getters['user/email']
  watch(email, (value) => {
    store.commit('user/setField', { key: 'email', value })
  })

  function afterSignUp (user) {
    redirectAfterSignUp()
    if (IS_WEB) {
      OneSignal.setExternalUserId(user.id)
    }
  }

  function catchError (e) {
    if (e.errors) {
      serverErrors.value = e.errors
    } else {
      notice.value = i18n.global.t('error.signUp')
      sentry.captureException(e)
    }
  }

  const submit = async () => {
    let user
    try {
      if (!save.value) return

      if (!newPhone.value?.isValid) {
        notice.value = i18n.global.t('error.phoneNotValid')
        serverErrors.value = {
          ...serverErrors.value,
          phone: [i18n.global.t('error.phoneNotValid')]
        }
        return
      }

      save.value = false
      serverErrors.value = []
      user = await store.dispatch('user/signUp', { email: email.value, password: password.value })
      AnalyticEvents.registration({
        method: 'email'
      })
      sentryConfigure(user, store.getters['user/username'])
    } catch (e) {
      catchError(e)
    }

    try {
      await store.dispatch('user/update', {
        ...user,
        phone: phone.value
      })
      afterSignUp(user)
    } catch (e) {
      router.push({ name: 'updateProfile' })
      catchError(e)
    } finally {
      save.value = true
    }
  }

  function onInputPhone (payload) {
    newPhone.value = payload
    phone.value = payload.e164 || ''

    if (!payload.isValid) {
      serverErrors.value = {
        ...serverErrors.value,
        phone: [i18n.global.t('error.phoneNotValid')]
      }
    } else {
      serverErrors.value = {
        ...serverErrors.value,
        phone: []
      }
    }
  }

  return {
    email,
    username,
    password,
    passwordAgain,
    phone,
    save,
    showPassword,
    showPasswordAgain,
    logo,
    clients,
    notice,
    submit,
    serverErrors,
    onInputPhone
  }
}
