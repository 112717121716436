import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lAuth pResetPassword -newPassword" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_password = _resolveComponent("as-password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.logo.src,
      alt: _ctx.logo.alt,
      class: "lAuth_logo pResetPassword_logo"
    }, null, 8, _hoisted_2),
    _createVNode(_component_as_password, {
      type: "reset",
      classTitle: "lAuth_title -password",
      title: _ctx.$t('changePassword'),
      onSubmit: _ctx.submit
    }, null, 8, ["title", "onSubmit"])
  ]))
}