
import { defineComponent } from 'vue'
import { Form as VForm, Field as VField } from 'vee-validate'

import AsInput from '@web/components/AsInput/AsInput.vue'
import AsNotice from '@web/components/AsNotice.vue'
import AsPhoneInput from '@web/components/AsPhoneInput.vue'

import i18n from '@web/plugins/i18n'

import useSignUpForm from '@web/composition/useSignUpForm'
import useErrors from '@web/composition/useErrors'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'

export default defineComponent({
  name: 'SignUpForm',
  setup () {
    useMeta({
      title: i18n.global.t('signUp')
    })
    const router = useRouter()

    const {
      serverErrors,
      getFirstError
    } = useErrors()

    const signUpForm = useSignUpForm({
      router,
      serverErrors,
      isLoadDataAfterSuccess: false
    })

    return {
      ...signUpForm,
      getFirstError
    }
  },

  components: {
    AsInput,
    AsNotice,
    AsPhoneInput,
    VForm,
    VField
  }
})
