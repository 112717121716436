import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lUserEdit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile = _resolveComponent("profile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_profile, {
      class: "lUserEdit_profile",
      title: _ctx.title,
      onSubmit: _ctx.submit,
      update: ""
    }, null, 8, ["title", "onSubmit"])
  ]))
}