
import { defineComponent, computed, onMounted } from 'vue'

import AsPassword from '@web/components.v2/Settings/Password/index.vue'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    AsPassword
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const logo = computed(() => store.getters['assets/logo'])

    function submit () {
      store.commit('user/setField', {
        key: 'requiredUpdatePassword',
        value: false
      })
      router.push({ name: PAGE_NAME.HOME })
    }

    onMounted(() => {
      if (!store.getters['user/user'].requiredUpdatePassword) {
        router.push({
          name: 'userPassword'
        })
      }
    })

    return {
      logo,
      submit
    }
  }
})
