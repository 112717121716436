
import { defineComponent, ref, computed, watch } from 'vue'

import { Form as VForm, Field as VField } from 'vee-validate'
import Analytics from '@web/services/Analytics/Analytics'

import AsInput from '@web/components/AsInput/AsInput.vue'
import AsNotice from '@web/components/AsNotice.vue'
import AsSupportAuth from '@web/components/AsSupportAuth.vue'

import UserApi from '@web/api/modules/User'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import useErrors from '@web/composition/useErrors'
import { useMeta } from '@web/common/vueMeta'

export default defineComponent({
  name: 'AccessRecovery',
  components: {
    AsInput,
    AsNotice,
    AsSupportAuth,
    VForm,
    VField
  },
  setup () {
    useMeta({
      title: i18n.global.t('restoringPassword')
    })
    const store = useStore()

    const email = ref('')
    const save = ref(true)
    const showPassword = ref(false)
    const notice = ref<string | string[]>('')
    const hideButtonIfSuccess = ref(false)

    email.value = store.getters['user/email']

    const login = computed({
      get: () => email.value,
      set: value => {
        email.value = value.toLowerCase()
      }
    })

    const logo = computed(() => store.getters['assets/logo'])

    async function submit () {
      try {
        if (!save.value) return

        save.value = false
        await UserApi.reset(email.value)
        notice.value = String(i18n.global.t('waitEmailWithPassword'))
        email.value = ''
        hideButtonIfSuccess.value = true
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.errors) {
          notice.value = useErrors().displayServerErrors(e.errors)
        } else {
          notice.value = String(i18n.global.t('error.accessRecovery'))
        }
        hideButtonIfSuccess.value = false
        save.value = true
      } finally {
        Analytics.send({
          category: 'restore_password',
          action: 'submit_form',
          label: hideButtonIfSuccess.value ? 'success' : String(i18n.global.t('error.accessRecovery'))
        })
      }
    }

    watch(email, (value) => {
      store.commit('user/setField', { key: 'email', value })
    })

    return {
      // params
      email,
      save,
      showPassword,
      notice,
      hideButtonIfSuccess,

      // computedParams
      login,
      logo,

      // methods
      submit
    }
  }
})
