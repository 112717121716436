
import { defineComponent, computed, ref } from 'vue'

import Profile from '@web/components.v2/Settings/Profile/index.vue'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    Profile
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const title = ref('crumb.editInformation')
    const menu = computed(() => store.getters['menu/general'])
    useMeta({
      title: i18n.global.t(title.value)
    })

    function submit () {
      if (store.getters['assets/authCollection'].length) {
        router.push({ name: 'linkSocialNetworks' })
      } else {
        router.push({ name: PAGE_NAME.HOME })
      }
    }

    return {
      title,
      menu,

      submit
    }
  }
})
