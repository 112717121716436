import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-074c3d86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lAuth -login" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "lAuth_title" }
const _hoisted_4 = ["data-text"]
const _hoisted_5 = {
  key: 0,
  class: "lAuth_loader"
}
const _hoisted_6 = { class: "lAuth_linkGroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_notice = _resolveComponent("as-notice")!
  const _component_as_input = _resolveComponent("as-input")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_as_support_auth = _resolveComponent("as-support-auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.logo.src,
      alt: _ctx.logo.alt,
      class: "lAuth_logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('forgotPassword')), 1),
    _createVNode(_component_as_notice, { notice: _ctx.notice }, null, 8, ["notice"]),
    (!_ctx.hideButtonIfSuccess)
      ? (_openBlock(), _createBlock(_component_v_form, {
          key: 0,
          onSubmit: _ctx.submit,
          class: "lAuth_form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_field, {
              name: "email",
              label: _ctx.$t('email'),
              rules: "required|email",
              modelValue: _ctx.login,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.login) = $event)),
              modelModifiers: { trim: true }
            }, {
              default: _withCtx(({ errorMessage }) => [
                _createVNode(_component_as_input, {
                  name: "email",
                  label: _ctx.$t('email'),
                  type: "email",
                  modelValue: _ctx.login,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.login) = $event)),
                  modelModifiers: { trim: true },
                  placeholder: _ctx.$t('input.email'),
                  error: errorMessage,
                  onKeyup: _withKeys(_ctx.submit, ["enter"])
                }, null, 8, ["label", "modelValue", "placeholder", "error", "onKeyup"])
              ]),
              _: 1
            }, 8, ["label", "modelValue"]),
            _createElementVNode("button", {
              class: "lAuth_button",
              "data-text": _ctx.$t('restore'),
              "data-cy": "btn-restore",
              type: "submit"
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('restore')) + " ", 1),
              (!_ctx.save)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                : _createCommentVNode("", true)
            ], 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: { name: 'login' },
        class: "lAuth_help"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('signIn')), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_as_support_auth)
  ]))
}