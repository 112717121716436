import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fd65792"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lAuth -signup" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "lAuth_title" }
const _hoisted_4 = ["data-text"]
const _hoisted_5 = {
  key: 0,
  class: "lAuth_loader"
}
const _hoisted_6 = { class: "lAuth_linkGroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_notice = _resolveComponent("as-notice")!
  const _component_as_input = _resolveComponent("as-input")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_as_phone_input = _resolveComponent("as-phone-input")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.logo.src,
      alt: _ctx.logo.alt,
      class: "lAuth_logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('signUp')), 1),
    _createVNode(_component_as_notice, { notice: _ctx.notice }, null, 8, ["notice"]),
    _createVNode(_component_v_form, {
      onSubmit: _ctx.submit,
      class: "lAuth_form"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_field, {
          modelValue: _ctx.username,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event)),
          modelModifiers: { trim: true },
          name: "email",
          label: _ctx.$t('email'),
          rules: "required|email",
          ref: "inputEmail"
        }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_as_input, {
              name: "email",
              label: _ctx.$t('email'),
              type: "email",
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              modelModifiers: { trim: true },
              placeholder: _ctx.$t('input.email'),
              error: _ctx.getFirstError('email', errors)
            }, null, 8, ["label", "modelValue", "placeholder", "error"])
          ]),
          _: 1
        }, 8, ["modelValue", "label"]),
        _createVNode(_component_v_field, {
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event)),
          modelModifiers: { trim: true },
          name: "passwordEL",
          label: _ctx.$t('password'),
          rules: "required"
        }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_as_input, {
              name: "passwordEL",
              label: _ctx.$t('password'),
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
              modelModifiers: { trim: true },
              "append-icon": _ctx.showPassword ? 'visibility' : 'visibilityOff',
              type: _ctx.showPassword ? 'text' : 'password',
              "onClick:append": _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword)),
              placeholder: _ctx.$t('input.password'),
              error: _ctx.getFirstError('password', errors),
              ref: "passwordEL"
            }, null, 8, ["label", "modelValue", "append-icon", "type", "placeholder", "error"])
          ]),
          _: 1
        }, 8, ["modelValue", "label"]),
        _createVNode(_component_v_field, {
          modelValue: _ctx.passwordAgain,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.passwordAgain) = $event)),
          modelModifiers: { trim: true },
          name: "passwordAgainEl",
          label: _ctx.$t('passwordAgain'),
          rules: "required|confirmed:@passwordEL"
        }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_as_input, {
              name: "passwordAgainEl",
              label: _ctx.$t('passwordAgain'),
              modelValue: _ctx.passwordAgain,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.passwordAgain) = $event)),
              modelModifiers: { trim: true },
              "append-icon": _ctx.showPasswordAgain ? 'visibility' : 'visibilityOff',
              type: _ctx.showPasswordAgain ? 'text' : 'password',
              "onClick:append": _cache[6] || (_cache[6] = ($event: any) => (_ctx.showPasswordAgain = !_ctx.showPasswordAgain)),
              placeholder: _ctx.$t('input.newPasswordAgain'),
              error: _ctx.getFirstError('passwordAgain', errors),
              ref: "passwordAgainEl"
            }, null, 8, ["label", "modelValue", "append-icon", "type", "placeholder", "error"])
          ]),
          _: 1
        }, 8, ["modelValue", "label"]),
        _createVNode(_component_v_field, {
          modelValue: _ctx.phone,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.phone) = $event)),
          modelModifiers: { trim: true },
          name: "phone",
          label: _ctx.$t('phone'),
          rules: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_as_phone_input, {
              error: _ctx.getFirstError('phone', errors),
              "model-value": _ctx.phone || '',
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.onInputPhone($event))),
              class: "lUserEdit_input",
              position: "left top"
            }, null, 8, ["error", "model-value"])
          ]),
          _: 1
        }, 8, ["modelValue", "label"]),
        _createElementVNode("button", {
          class: "lAuth_button login_button",
          "data-text": _ctx.$t('register'),
          type: "submit"
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('register')) + " ", 1),
          (!_ctx.save)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5))
            : _createCommentVNode("", true)
        ], 8, _hoisted_4)
      ]),
      _: 1
    }, 8, ["onSubmit"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: { name: 'login' },
        class: "lAuth_link lAuth_login"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('haveAccount')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}